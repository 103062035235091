import React, { useState } from 'react'
import StoryContent1 from '../components/StoryContent1'
import Footer from '../components/Footer'

const Story = () => {
    return (
        <div>
            <StoryContent1></StoryContent1>
            <Footer></Footer>
        </div>
    )
}

export default Story
