import React from 'react'
import { FooterContainer, Socmed, FlexContainer, FlexContainer2, ImageCrop, CardWrapper, FirstCaption, SecondCaption, ThirdCaption, FourthCaption, FifthCaption } from './FooterElement'
import imgSrc from '../../images/contact_us_background.png'
import WaIcon from '../../images/wa_icon.svg'
import IgIcon from '../../images/ig_icon.svg'
import TwitterIcon from '../../images/twitter_icon.svg'

import { First } from 'react-bootstrap/esm/PageItem'

const Footer = () => {
    return (
        <FooterContainer>
            <FlexContainer>
                <FirstCaption>Anthem<br />
            PT. Gema Digital Indonesia<br />
            Bandung, West Java
            </FirstCaption>
                <FlexContainer2>
                    <Socmed style={{ marginRight: '20px', marginTop: '5px' }}>Find us on</Socmed>
                    <a href="https://wa.me/628112321191" target="_blank" style={{ marginTop: 5 }}>
                        <img src={WaIcon} style={{ marginRight: '20px' }}></img>
                    </a>
                    <a href="https://instagram.com/hello.anthem" target="_blank" style={{ marginTop: 5 }}>
                        <img src={IgIcon} style={{ marginRight: '20px' }}></img>
                    </a>
                    {/* <img src={TwitterIcon} style={{ marginRight: '20px' }}></img> */}
                </FlexContainer2>
            </FlexContainer>
            <hr style={{ border: "1px solid grey" }} />
            <FlexContainer>
                <SecondCaption>
                    Redefining Experience since 2020. Anthem. All Rights Reserved.
                </SecondCaption>
                <SecondCaption>
                    Informasi dan pengaduan konsumen DITJEN PTKN 085311111010
                </SecondCaption>
            </FlexContainer>
        </FooterContainer>
    )
}

export default Footer
