import logo from './logo.svg';
import './App.css';

import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Home from './pages/';
import Story from './pages/Story';
import Signup from './pages/Signup';
import Shop from './pages/Shop';
import Blend from './pages/Blend';
import Landing from './pages/Landing';

function App() {
  return (
    <Router>
      <div>
        <Home />
        <Switch>
          <Route exact path='/'>
            <Landing/>
          </Route>
          <Route path='/story'>
            <Story />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
