import React from 'react'
import Content1 from '../components/Content1'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Content2 from '../components/Content2'
import ClientCarousel from '../components/ClientCarousel'

const Landing = () => {

    return (
        <div>
            <Content1 />
            <Content2 />
            <ClientCarousel />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default Landing
